<template>
	<div class="notice_popup human int">
		<div class="tit" style="margin-left: 9px;">프로젝트에 지원하기 위한 필수 등록항목 입니다!</div>
		
		<div class="select-btn">
			<div class="btn" @click="$emit('close', {viewNm:'PRF101M01'})">
				이력서 {{ prfCnt }} / 
				<span v-if="prfCnt == 0" style="color: red;">미등록</span>
				<span v-else>등록완료</span>
			</div>
			<div class="btn" @click="$emit('close', {viewNm:'PRF104M01'})">
				소속사 {{ mberAsgRegYn }} /
				<span v-if="mberAsgRegYn == 'N'" style="color: red;">미등록</span>
				<span v-else>등록완료</span>
			</div>
		</div>

		<div class="member" style="line-height: 35px;"> ※ ‘프로필 관리’ 메뉴에서 등록 및 수정이 가능하며, 프로젝트에 지원하기 위한 필수적인 등록항목 입니다!</div>
	</div>
</template>

<script>
export default {
	props: ['param'],

	data() {
		return {
			prfCnt : this.param.prfCnt,			
			mberAsgRegYn : this.param.mberAsgRegYn,
		};
	},

	methods : {
		
	}
}
</script>